<template>
  <div>
    <privacy-layout>
      <router-view />
      <v-col cols="12">
        <v-row>
          <div class="display-1 font-weight-bold pp-header-color">
            Terms & Conditions
          </div>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-row>
          <p class="body-2 pp-text-color">
            Welcome to Trade Now! Our mobile app and website allow customers to
            find and engage with trusted tradespeople in your local area for
            household and commercial needs. TradeNow offers 24Hr payment to its
            tradespeople, while customers can arrange for services today and pay
            for them later. TradeNow offers 0% repayments with our finance
            Partner. To provide the best service, we may collect some personal
            information from you.
          </p>
          <p class="body-2 pp-text-color">
            We understand that protecting your personal information is
            important. This Privacy Policy sets out our commitment to protecting
            the privacy of personal information provided to us, or otherwise
            collected by us, offline or online, including through our website
            and mobile application (Site). In this Privacy Policy we, usor
            ourmeans Trade Now Enterprises Pty Ltd ACN 629 746 363.
          </p>
        </v-row>
        <v-row>
          <div class=".subtitle-1 font-weight-bold pp-header-color">
            Personal information
          </div>
        </v-row>

        <v-row>
          <div class="body-2 pp-text-color">
            The types of personal information we may collect about you include:
          </div>
        </v-row>
        <v-row>
          <ul class="body-2 pp-text-color mb-4">
            <li>your name</li>
            <li>images of you</li>
            <li>
              your contact details, including email address, street address
              and/or telephone number;
            </li>
            <li>
              your tradesperson licence (or similar), where you are a
              tradesperson listed on our Site;
            </li>
            <li>
              your credit card or payment details (including through our third
              party financier and/or third party payment processor);
            </li>
            <li>your preferences and/or opinions;</li>
            <li>information you provide to us through customer surveys;</li>
            <li>your sensitive information as set out below;</li>
            <li>
              details of products and services we have provided to you and/or
              that you have enquired about, and our response to you;
            </li>
            <li>
              your browser session and geo-location data, device and network
              information, statistics on page views and sessions, acquisition
              sources, search queries and/or browsing behaviour;
            </li>
            <li>
              information about your access and use of our Site, including
              through the use of Internet cookies, your communications with our
              Site, the type of browser you are using, the type of operating
              system you are using and the domain name of your Internet service
              provider;
            </li>
            <li>
              additional personal information that you provide to us, directly
              or indirectly, through your use of our Site, associated
              applications, associated social media platforms and/or accounts
              from which you permit us to collect information; and
            </li>
            <li>
              any other personal information requested by us and/or provided by
              you or a third party.
            </li>
            <li>
              We may collect these types of personal information directly from
              you or from third parties.
            </li>
          </ul>
        </v-row>

        <v-row>
          <div class=".subtitle-1 font-weight-bold pp-header-color">
            Collection and use of personal information
          </div>
        </v-row>
        <v-row>
          <ul class="body-2 pp-text-color mb-4">
            <li>to contact and communicate with you;</li>
            <li>
              for internal record keeping, administrative purposes, invoicing
              and billing purposes;
            </li>
            <li>
              for analytics, market research and business development, including
              to operate and improve our Site, associated applications and
              associated social media platforms;
            </li>
            <li>
              to run competitions and/or to offer additional benefits to you;
            </li>
            <li>
              for advertising and marketing, including to send you newsletters
              and promotional information about our products and services and
              information that we consider may be of interest to you;
            </li>
            <li>
              to comply with our legal obligations and resolve any disputes that
              we may have; and
            </li>
            <li>to consider your employment application.</li>
          </ul>
        </v-row>

        <v-row>
          <div class=".subtitle-1 font-weight-bold pp-header-color">
            Disclosure of personal information to third parties
          </div>
        </v-row>
        <v-row>
          <div class="body-2 pp-text-color">
            We may disclose personal information to:
          </div>
        </v-row>
        <v-row>
          <ul class="body-2 pp-text-color mb-4">
            <li>service providers listed on our Site such as tradespersons;</li>
            <li>TradeNow finance partner</li>
            <li>
              third party service providers for the purpose of enabling them to
              assist us in providing our services to you, including (without
              limitation) IT service providers, data storage, web-hosting and
              server providers, debt collectors, maintenance or problem-solving
              providers, marketing or advertising providers such as SendGrid,
              professional advisors and payment systems operators;
            </li>
            <li>our employees, contractors and/or related entities;</li>
            <li>our existing or potential agents or business partners;</li>
            <li>sponsors or promoters of any competition we run;</li>
            <li>
              anyone to whom our business or assets (or any part of them) are,
              or may (in good faith) be, transferred;
            </li>
            <li>
              credit reporting agencies, courts, tribunals and regulatory
              authorities, in the event you fail to pay for goods or services we
              have provided to you;
            </li>
            <li>
              courts, tribunals, regulatory authorities and law enforcement
              officers, as required by law, in connection with any actual or
              prospective legal proceedings, or in order to establish, exercise
              or defend our legal rights;
            </li>
            <li>
              third parties, including agents or sub-contractors, who assist us
              in providing information, products, services or direct marketing
              to you This may include parties located, or that store data
              outside of Australia; and
            </li>
            <li>
              third parties to collect and process data, such as Google
              Analytics This may include parties located, or that store data
              outside of Australia.
            </li>
          </ul>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            By providing us with personal information, you consent to the
            disclosure of your information outside of Australia and acknowledge
            that we are not required to ensure that overseas recipients handle
            that personal information in compliance with Australian Privacy law.
            You acknowledge that some overseas third parties may not be
            regulated by the Privacy Act and the Australian Privacy Principles
            in the Privacy Act and if any third party engages in any act or
            practice that contravenes the Australian Privacy Principles, it
            would not be accountable under the Privacy Act and you will not be
            able to seek redress under the Privacy Act.
          </p>
        </v-row>
        <v-row>
          <div class=".subtitle-1 font-weight-bold pp-header-color">
            How we treat personal information that is also sensitive information
          </div>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            Sensitive information is a sub-set of personal information that is
            given a higher level of protection under the Australian Privacy
            Principles. Sensitive informationmeans information relating to your
            racial or ethnic origin, political opinions, religion, trade union
            or other professional associations or memberships, philosophical
            beliefs, sexual orientation or practices, criminal records, health
            information or biometric information.
          </p>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            If we collect Sensitive Information, provided you consent, your
            sensitive information may only be used and disclosed for purposes
            relating to the primary purpose for which the sensitive information
            was collected.
          </p>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            Sensitive information may also be used or disclosed if required or
            authorised by law.
          </p>
        </v-row>

        <v-row>
          <div class=".subtitle-1 font-weight-bold pp-header-color">
            Your rights and controlling your personal information
          </div>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            <strong>Choice and consent:</strong> Please read this Privacy Policy
            carefully. By providing personal information to us, you consent to
            us collecting, holding, using and disclosing your personal
            information in accordance with this Privacy Policy. You do not have
            to provide personal information to us, however, if you do not, it
            may affect your use of this Site or the products and/or services
            offered on or through it.
          </p>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            <strong>Information from third parties:</strong> If we receive
            personal information about you from a third party, we will protect
            it as set out in this Privacy Policy. If you are a third party
            providing personal information about somebody else, you represent
            and warrant that you have such person’s consent to provide the
            personal information to us.
          </p>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            <strong>Restrict:</strong> If you have previously agreed to us using
            your personal information for direct marketing purposes, you may
            change your mind at any time by contacting us using the details
            below.
          </p>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            <strong>Access:</strong> You may request details of the personal
            information that we hold about you. An administrative fee may be
            payable for the provision of such information.
          </p>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            <strong>Correction:</strong> If you believe that any information we
            hold about you is inaccurate, out of date, incomplete, irrelevant or
            misleading, please contact us using the details below. We will take
            reasonable steps to correct any information found to be inaccurate,
            incomplete, misleading or out of date.
          </p>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            <strong>Complaints:</strong> If you wish to make a complaint about
            how we have handled your personal information, please contact us
            using the details below and provide us with full details of the
            complaint. We will promptly investigate your complaint and respond
            to you, in writing, setting out the outcome of our investigation and
            the steps we will take to deal with your complaint.
          </p>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            <strong>Unsubscribe:</strong> To unsubscribe from our e-mail
            database or opt-out of communications (including marketing
            communications), please contact us using the details below or
            opt-out using the opt-out facilities provided in the communication.
          </p>
        </v-row>

        <v-row>
          <div class=".subtitle-1 font-weight-bold pp-header-color">
            Storage and security
          </div>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            We are committed to ensuring that the personal information we
            collect is secure. In order to prevent unauthorised access or
            disclosure, we have put in place suitable physical, electronic and
            managerial procedures to safeguard and secure the personal
            information and protect it from misuse, interference, loss and
            unauthorised access, modification and disclosure.
          </p>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            We cannot guarantee the security of any information that is
            transmitted to or by us over the Internet. The transmission and
            exchange of information is carried out at your own risk. Although we
            take measures to safeguard against unauthorised disclosures of
            information, we cannot assure you that the personal information we
            collect will not be disclosed in a manner that is inconsistent with
            this Privacy Policy.
          </p>
        </v-row>

        <v-row>
          <div class=".subtitle-1 font-weight-bold pp-header-color">
            Cookies and web beacons
          </div>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            We may use cookies on our Site from time to time. Cookies are text
            files placed in your computer's browser to store your preferences.
            Cookies, by themselves, do not tell us your email address or other
            personally identifiable information. However, they do allow third
            parties, such as Google and Facebook, to cause our advertisements to
            appear on your social media and online media feeds as part of our
            retargeting campaigns. If and when you choose to provide our Site
            with personal information, this information may be linked to the
            data stored in the cookie.
          </p>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            You can block cookies by activating the setting on your browser that
            allows you to refuse the setting of all or some cookies. However, if
            you use your browser settings to block all cookies (including
            essential cookies) you may not be able to access all or parts of our
            site.
          </p>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            We may use web beacons on our Site from time to time. Web beacons
            (also known as Clear GIFs) are small pieces of code placed on a web
            page to monitor the visitor’s behaviour and collect data about the
            visitor’s viewing of a web page. For example, web beacons can be
            used to count the users who visit a web page or to deliver a cookie
            to the browser of a visitor viewing that page.
          </p>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            We may use Google Analytics to collect and process data. To find out
            how Google uses data when you use third party websites or
            applications, please see www.google.com/policies/privacy/partners/
            or any other URL Google may use from time to time.
          </p>
        </v-row>

        <v-row>
          <div class=".subtitle-1 font-weight-bold pp-header-color">
            Links to other websites
          </div>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color">
            Our Site may contain links to other websites. We do not have any
            control over those websites and we are not responsible for the
            protection and privacy of any personal information which you provide
            whilst visiting those websites. Those websites are not governed by
            this Privacy Policy.
          </p>
        </v-row>

        <v-row>
          <div class=".subtitle-1 font-weight-bold pp-header-color">
            Amendments
          </div>
        </v-row>
        <v-row>
          <p class="body-2 pp-text-color m-0">
            We may, at any time and at our discretion, vary this Privacy Policy
            by publishing the amended Privacy Policy on our Site. We recommend
            you check our Site regularly to ensure you are aware of our current
            Privacy Policy.
          </p>
        </v-row>
        <v-row>
          <div class="body-2 pp-text-color m-0">
            For any questions or notices, please contact our Privacy Officer at:
          </div>
        </v-row>
        <v-row>
          <div class="body-2 pp-text-color m-0">
            Trade Now Enterprises Pty Ltd ACN 629 746 363
          </div>
        </v-row>
        <v-row>
          <div class="body-2 pp-text-color m-0">
            Email: Info@tradenowapp.com
          </div>
        </v-row>
        <v-row>
          <div class="body-2 pp-text-color m-0">Last update: 29 May 2019</div>
        </v-row>
        <v-row>
          <div class="body-2 pp-text-color m-0">© LegalVisionILP Pty Ltd</div>
        </v-row>
      </v-col>
    </privacy-layout>
  </div>
</template>

<script>
import PrivacyLayout from '@/layouts/PrivacyLayout'

export default {
  components: { PrivacyLayout },
}
</script>

<style scoped></style>
